import { observable, action, computed } from "mobx";
import arrayMove from 'array-move';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import { blobToBinaryString } from '../util/blob';
import resize from '../util/resize';

const array_chunks = (array, chunk_size) => Array(Math.ceil(array.length / chunk_size)).fill().map((_, index) => index * chunk_size).map(begin => array.slice(begin, begin + chunk_size));

class AppState
{
    @observable appname = "方糖小剧场·编辑器 H2Editor";
    @observable bookdata = {};
    @observable chapter_filter = "";
    @observable focused_talk_id = 0;
    @observable currentChapterNumber = 1;

    @observable toast_class = "";
    @observable toast_text = "";
    
    @observable tosend_text = "";
    @observable tosend_role_id = 0;
    @observable focused_talk_id = 0;

    @observable show_preview = true;
    @observable preview_talk_id = 0;

    @observable download_pages = [];
    @observable limit = 50;

    @observable editing_role = false;
    local_file_path = false;
    
    @observable show_print = false;
    
    exportBook()
    {
        // let book = {};
        
        // 首先，获取本章的内容
        let talks = this.currentTalks;

        // 然后，从talks中抽取用到的roles
        let roles = [];
        talks.forEach( item =>{
            if( !this.find_by_id( roles , item.role_id ) )
            {
                let _role =  {...this.getRole( item.role_id )};
                _role.note = null;
                _role.isstar = null;
                roles.push( _role );
            }
                
        });

        // 最后补充下基本信息
        let meta = {...this.bookdata.bookinfo};

        // 获取本章名称
        const chapter_name = this.currentChapter.title;
        
        if( chapter_name.trim().length > 0 )
            meta.name += '·' + chapter_name;
        
            const book = { meta , roles , talks };

        const content = JSON.stringify( book );

        FileSaver.saveAs(new Blob([content], {
            type: "text/json;charset=utf-8"
          }), meta.name + '.' + Date.now() + ".h2book");
    }
    
    async downImage()
    {
        let sdiv = document.querySelector("#sctmp");
        if( !sdiv )
        {
            sdiv = document.createElement("div");
        
            sdiv.style.width="440px";
            sdiv.style.padding="20px";
            
            sdiv.setAttribute("id", "sctmp");
            sdiv.classList.add('snap-talk-list');
            document.body.appendChild(sdiv);
        }
        
        if( this.download_pages.length > 0 )
        {
            const page =this.download_pages.shift();
            this.bookdata.talks = page;
            // console.log( "page", JSON.stringify(page) );
            
            window.setTimeout( async ()=>{
                sdiv.innerHTML = document.querySelector(".printbox .talklist-ro .thelist").innerHTML;

                const canvas = await html2canvas(document.querySelector("#sctmp"),{scale:2});
                // canvas.toBlob( ( blob ) =>
                // {
                //     FileSaver( blob , this.bookdata.bookinfo.name + '.' + Date.now() + ".png" );
                // } );
                //const small_base64 = await resize( base64 , 880 , 6000 );
                //await FileSaver( small_base64 , this.bookdata.bookinfo.name + '.' + Date.now() + ".png" );
                const base64 = await canvas.toDataURL();
                await FileSaver( base64 , this.bookdata.bookinfo.name + '.' + Date.now() + ".png" );
                await this.downImage();
            }, 1000 );   
        }
        else
        {
            this.show_print = false;
            this.bookdata.talks = this.saved_talks;
            document.querySelector("#sctmp").remove();
        }
    }
    
    async exportImages()
    {
        this.saved_talks = this.bookdata.talks;
        const current_talks = this.currentTalks;
        this.download_pages = array_chunks( current_talks, this.limit );
        this.show_print = true;
        await this.downImage();
        return ;
        if( pages.length > 0 )
        {
            for( const page of pages )
            {
                console.log( "page" , JSON.stringify(page) );
                this.bookdata.talks = page;
                //this.preview_talk_id = page[page.length-1].id;
                this.previewTo( page[page.length-1].id );
                
                //alert("123");
                // let sdiv;
                // if( !document.querySelector("#sctmp") )
                // {
                //     sdiv = document.createElement("div");
                
                //     sdiv.style.width="440px";
                //     sdiv.style.padding="20px";

                //     sdiv.setAttribute("id", "sctmp");
                //     sdiv.classList.add('snap-talk-list');
                //     document.body.appendChild(sdiv);
                
                // }
                
                // sdiv.innerHTML = document.querySelector(".previewbox .talklist-ro .thelist").innerHTML;

                // const canvas = await html2canvas(document.querySelector("#sctmp"));
                // canvas.toBlob( ( blob ) =>
                // {
                //     FileSaver( blob , this.bookdata.bookinfo.name + '.' + Date.now() + ".png" );
                    
                // } );
            }

            // document.body.removeChild(document.querySelector("#sctmp"));
        }

        console.log( JSON.stringify(this.bookdata.talks) )
        this.bookdata.talks = saved_talks;

        
    }

    @computed get previewTalks()
    {
        if (!this.bookdata.talks) return [];
        
        // 获取当前章节的所有对话，并按order排序
        const talks = this.bookdata.talks
            .filter(item => item.chapter_id === this.currentChapterNumber)
            .sort((a, b) => parseInt(a.order) - parseInt(b.order));

        // 如果有指定预览的对话ID，则只返回到该ID为止的对话
        // if (this.preview_talk_id > 0) {
        //     const index = talks.findIndex(talk => talk.id === this.preview_talk_id);
        //     return index >= 0 ? talks.slice(0, index + 1) : talks;
        // }
        
        return talks;
    }

    @action previewTo( id )
    {
        this.show_preview = true;
        this.preview_talk_id = id;
    }
    
    @action createChapter()
    {
        const newid = parseInt(this.maxid( this.bookdata.chapters ))+1;
        console.log( "in " , newid );
        const chapter = {  "id": newid , "title":"第"+newid+"章" , "note":""  };
        this.bookdata.chapters.push( chapter );
        this.save();
        return true;
    }

    @action
    repeatChapter( id )
    {
        const old_chapter = this.find_by_id( this.bookdata.chapters , id ) ;
        
        let chapter = {...old_chapter};

        if( chapter ) 
        {
            chapter.id = parseInt(this.maxid( this.bookdata.chapters ))+1;
            chapter.title = chapter.title + 'Copy';

            this.bookdata.chapters.push( chapter );

            console.log("repeat chapter");

            const talks = this.bookdata.talks.filter( item => item.chapter_id == id ).sort( ( a , b ) => parseInt(a.order) - parseInt(b.order)   );

            let max_chapter_id = parseInt(this.maxid( this.bookdata.talks ))+1;
            let max_chapter_order = parseInt(this.maxid( this.bookdata.talks , 'order' ))+1;

            talks.forEach( item => 
            {
                let new_item = {...item};
                new_item.id = max_chapter_id++;
                new_item.chapter_id = chapter.id;
                new_item.order = max_chapter_order++;

                console.log( "foreach" ,  new_item , JSON.stringify(item) );

                this.bookdata.talks.push( new_item );
            });

            // this.currentChapterNumber = chapter.id;
            
            // this.save();

        } 
    }
    
    @action
    updateChapterTitle( id , title )
    {
        this.bookdata.chapters.forEach( (item , index) => {
        if( item.id == id ) this.bookdata.chapters[index].title = title;
        } );
        this.save();
        return true;
    }

    @action 
    removeChapter( id )
    {
        this.bookdata.chapters = this.bookdata.chapters.filter( item => item.id != id );

        this.bookdata.talks = this.bookdata.talks.filter( item => item.chapter_id != id );

        this.save();
    }
    
    @action
    removeRole( id )
    {
        this.bookdata.roles = this.bookdata.roles.filter( item => item.id != id );

        this.bookdata.talks = this.bookdata.talks.filter( item => item.role_id != id );

        this.save();
    }
    
    @action
    updateRole( id , name , base64, ismain , note )
    {
        const role = Object.assign( this.getRole( id ) , {  id , name , base64 , ismain , note });

        this.bookdata.roles.forEach( (item , index) => {
        if( item.id == id ) this.bookdata.roles[index] = role;
        } );
        this.save();
        return true;
    }

    @action 
    modifyRole( id )
    {
        
        this.editing_role = this.getRole( id );

    }

    @action
    starRole( id )
    {
        
        this.bookdata.roles.forEach( (item , index) => {
            if( item.id == id )
            {
                if( this.bookdata.roles[index].isstar )
                {
                    this.bookdata.roles[index].isstar = false;
                }
                else
                {
                    this.bookdata.roles[index].isstar = true;
                }
            } ;
        } );
        this.save();
        // console.log( this.getRole( id ) );
        return true;
    }

    @action
    mainRole( id )
    {
        
        this.bookdata.roles.forEach( (item , index) => {
            if( item.id == id )
            {
                if( this.bookdata.roles[index].ismain )
                {
                    this.bookdata.roles[index].ismain = false;
                }
                else
                {
                    this.bookdata.roles[index].ismain = true;
                }
            } ;
        } );
        this.save();
        // console.log( this.getRole( id ) );
        return true;
    }
    
    @action
    setPreview( id )
    {
        this.preview_talk_id= id;
        this.show_preview = ture;
    }
    
    @action
    removeTalk( id )
    {
        this.bookdata.talks = this.bookdata.talks.filter( item => item.id != id );
        this.save();
    }
    
    @action
    updateTalk( id , text )
    {
        this.bookdata.talks.forEach( (item , index) => {
        if( item.id == id ) this.bookdata.talks[index].text = text;
        } );
        this.save();
    }

    @action
    updateTalkOrder( id , order )
    {
        this.bookdata.talks.forEach( (item , index) => {
        if( item.id == id ) this.bookdata.talks[index].order = order;
        } );
        this.save();
    }

    @action
    updateTalkRole( id , role_id )
    {
        this.bookdata.talks.forEach( (item , index) => {
        if( item.id == id )
        {
            this.bookdata.talks[index].role_id = role_id;
            if( role_id == 0 )
                this.bookdata.talks[index].type="aside";
            else
                this.bookdata.talks[index].type="role";

                this.bookdata.talks[index].role_ismain = this.getRole( role_id ).ismain;
        }
        } );
        
        this.save();
    }


    @action
    nextRole( id )
    {
        let roles = this.bookdata.roles.filter( item => item.isstar );

        if( roles.length < 2 )
            roles = this.bookdata.roles;
        
        let ret = roles[0].id;
        roles.forEach( (item , index) => {
        if( item.id == id )
        {
            if( roles[index+1] ) ret = roles[index+1].id;
        }
        } );
        return ret;
    }


    @action
    async openFile( file )
    {
        const zip = await JSZip.loadAsync( file );
        const data = await zip.file("h2content.json").async("string");
        const jsondata = JSON.parse( data );

        if( jsondata )
        {
            this.bookdata = {...jsondata};
            // this.save();
        } 
    }


    async saveToFile()
    {
        const data = this.bookdata;
        const content = JSON.stringify( data );
        
        if( window.require  )
        {
            
            if( !this.local_file_path )
            {
                const {dialog} = window.require('electron').remote;
                const fs = window.require('fs');

                this.local_file_path = await dialog.showSaveDialog( {"filters":[{"name":"小剧场文档","extensions":["h2doc"]}]} );

            }


            if( this.local_file_path )
            {
                // 直接保存本地文件内容
                const fs = window.require('fs');
                //console.log( content );
                fs.writeFile( this.local_file_path, content , (err) => {
                    if(err){
                        alert("An error ocurred creating the file "+ err.message)
                    }
                                
                    this.toast("保存成功");
                });

                window.localStorage.setItem( "last_file" ,  this.local_file_path );
            }
            
            
        }
        else
        {
            //console.log( content );
            // 下载
            FileSaver.saveAs(new Blob([content], {
                type: "text/json;charset=utf-8"
              }), this.bookdata.bookinfo.name + '.' + Date.now() + ".h2doc");
        }
        console.log("Download");
    }

    @computed
    get currentTalks()
    {
        if( !this.bookdata.talks ) return false;
        
        // 确保只返回当前章节的对话，并按order排序
        return this.bookdata.talks
            .filter( item => item.chapter_id === this.currentChapterNumber )
            .sort( ( a , b ) => parseInt(a.order) - parseInt(b.order) );
    }

    @action
    createTalk()
    {
        const type = this.tosend_role_id > 0 ? "role" : "aside";

        
        const role_ismain = this.find_by_id( this.bookdata.roles , this.tosend_role_id  ).ismain;

        const talk = { "id":parseInt(this.maxid( this.bookdata.talks ))+1 , "chapter_id":this.currentChapterNumber  , type ,"role_id":this.tosend_role_id , "role_ismain": role_ismain , "order":parseInt(this.maxid( this.bookdata.talks , "order" ))+1  , "text" : this.tosend_text };

        this.bookdata.talks.push( talk );
        this.save();
    }

    @action
    appendTalk( the_id )
    {
        console.log("append");
        const type = this.tosend_role_id > 0 ? "role" : "aside";
        const role_ismain = this.find_by_id( this.bookdata.roles , this.tosend_role_id  ).ismain;

        const newid = parseInt(this.maxid( this.bookdata.talks ))+1;
        let talk = { "id": newid, "chapter_id":this.currentChapterNumber  , type ,"role_id":this.tosend_role_id , "role_ismain": role_ismain , "order":parseInt(this.maxid( this.bookdata.talks , "order" ))+1  , "text" : this.tosend_text };
        
        // this.bookdata.talks.push( talk );
        let order = 1;
        let new_order = 0;
        let order_array = [];
        this.currentTalks.forEach( ( item ) => {
        
        order_array.push( {"id":item.id,"order":order} );
        // this.updateTalkOrder( item.id , order );
        order++;

        if( item.id == the_id )
        {
            console.log("find id", the_id);
            new_order = order++;
            // order_array.push( {"id":newid,"order":new_order} );
            // order++;
        }
        } );

        if( new_order > 0 )
        {
        // console.log( new_order , order_array );
        order_array.forEach( (item) =>
        {
            this.updateTalkOrder( item.id , item.order );
        } );
        }
        
        talk.order= new_order;
        this.bookdata.talks.push( talk );
        this.save();
    }
    
    @action
    set_tosend_role( id )
    {
        this.tosend_role_id = id;
        this.toast("角色已切换");
    }

    @computed
    get tosend_role()
    {
        if( !this.bookdata.roles ) return false;
        return this.find_by_id(this.bookdata.roles,this.tosend_role_id);
    }

    getRole( id )
    {
        return this.find_by_id(this.bookdata.roles,id);
    }

    find_by_id( array , id )
    {
        return array.filter( item => item.id == id )[0];
    }

    @action
    createRole( name , base64, ismain , note )
    {
        
        const role = {  "id":parseInt(this.maxid( this.bookdata.roles ))+1 , name , base64 , ismain , note , "isstar":false };
        this.bookdata.roles.unshift( role );
        this.save();
        return true;
    }

    

    maxid( array , field = 'id' )
    {
        if( !Array.isArray( array ) ) return 1;
        
        let max = 0;
        array.forEach( item => { if( item[field] > max ) max = item[field] } );
        return max;
    }
    
    toast( text )
    {
      this.toast_text = text;
      this.toast_class = 'show';
      setTimeout(() => {
          this.toast_class = '';    
      }, 4000);
    }

    
    @action
    saveBookInfo( text , field , tofile = true )
    {
        this.bookdata.bookinfo[field] = text;
        if( tofile ) this.save();
    }
    
    @action
    saveChapterNote( text , tofile = true )
    {
        this.bookdata.chapters.forEach( (item , key) => {
        if( item.id == this.currentChapterNumber ) this.bookdata.chapters[key].notes =text;
        }   );
        if( tofile ) this.save();
    }
    
    @action
    reOrder( oldIndex , newIndex )
    {
        // console.log( oldIndex , newIndex );
        this.bookdata.chapters = arrayMove(this.bookdata.chapters, oldIndex, newIndex);
        this.save();
    }
    
    save()
    {
        // save to localstorage
        // console.log( JSON.stringify(this.bookdata) );
        window.localStorage.setItem( "h2editor_data" , JSON.stringify(this.bookdata) );
    }

    load()
    {
        
        const last_file = window.localStorage.getItem( "last_file");

        if( last_file && window.require )
        {
            const fs = window.require('fs');
            if( fs.existsSync( last_file ) )
            {
                const data = fs.readFileSync( last_file , 'utf8' );
                const jsondata = JSON.parse( data );
                    // console.log( " jsondata " , jsondata );

                if( jsondata )
                {
                    this.bookdata = {...jsondata};
                    this.local_file_path = last_file;
                }
                
                return true;
            }
            else
                console.log( "last_file not exists" , last_file );
        }else
            console.log( "last_file undefined" , last_file );

        const bookdata = JSON.parse( window.localStorage.getItem( "h2editor_data") );

        if( bookdata ) this.bookdata =  bookdata;
        else this.loadBookData();

        if( window.localStorage.getItem( "h2editor_preview") === null )         this.show_preview = true;
        else
            this.show_preview = parseInt( window.localStorage.getItem( "h2editor_preview") ) == 1 ;
        
        
        

        // console.log( "pv" , this.show_preview )
    }

    @action
    togglePreview()
    {
        this.show_preview = !this.show_preview;
        window.localStorage.setItem( "h2editor_preview",this.show_preview ? 1 : 0 );
    }

    @action setBookData( jsondata )
    {
        // 同时支持 h2doc 和 h2book 格式的打开
        // 拥有 chapters 和 bookinfo 的是 h2doc
        if( jsondata.chapters && jsondata.bookinfo )
        {
            this.bookdata = {...jsondata};
        }
        else
        {
            // 这里应该是 h2book 格式，特点是没有 chpaters 和 bookinfo( 改为 meta )
            const chapters = [{"id":1,"title":"第1章" , "content":[] , "notes":""}];
            jsondata.talks.forEach( item => item.chapter_id = 1 );
            
            // 使用导入对话框来选择性导入
            this.importDialog && this.importDialog.show(jsondata);
            return;
        }
    }

    @action
    importBookData(jsondata, options) {
        const { importMeta, importRoles, correctRoleIds, importTalks, targetChapterId } = options;
        
        // 导入基本信息
        if (importMeta) {
            this.bookdata.bookinfo = {
                author: jsondata.meta.author,
                name: jsondata.meta.name,
                detail: jsondata.meta.detail,
                link: jsondata.meta.link
            };
        }

        // 处理角色映射关系
        const existingRoles = this.bookdata.roles || [];
        const roleMapping = new Map(); // 用于存储角色ID的映射关系
        
        // 先建立导入角色的ID到名字的映射，方便后续查找
        const importRoleMap = new Map();
        // 添加旁白角色到映射中
        importRoleMap.set(0, { id: 0, name: "旁白", ismain: false });
        jsondata.roles.forEach(role => {
            importRoleMap.set(role.id, role);
        });
        
        // 找出重名角色并建立映射关系
        jsondata.roles.forEach(importRole => {
            const existingRole = existingRoles.find(r => r.name === importRole.name);
            if (existingRole) {
                console.log(`找到重名角色: ${importRole.name}, 原ID: ${importRole.id}, 映射到ID: ${existingRole.id}`);
                // 如果找到重名角色，建立ID映射关系
                roleMapping.set(importRole.id, {
                    id: existingRole.id,
                    ismain: existingRole.ismain,
                    name: existingRole.name // 添加名字用于调试
                });
            }
        });

        // 导入角色（排除重名的角色）
        if (importRoles) {
            // 获取当前最大角色ID
            const maxExistingId = Math.max(...existingRoles.map(r => r.id));
            let nextId = maxExistingId + 1;

            // 只导入不重名的角色，并确保ID不重复
            const newRoles = jsondata.roles
                .filter(role => !roleMapping.has(role.id))
                .map(role => {
                    // 为新角色分配新的ID，并建立映射关系
                    const newRole = { ...role, id: nextId };
                    roleMapping.set(role.id, {
                        id: nextId,
                        ismain: role.ismain,
                        name: role.name
                    });
                    console.log(`新角色 ${role.name}: 原ID ${role.id} -> 新ID ${nextId}`);
                    nextId++;
                    return newRole;
                });

            console.log(`新增角色: ${newRoles.map(r => `${r.name}(${r.id})`).join(', ')}`);
            this.bookdata.roles = [...existingRoles, ...newRoles];
        }

        // 导入对话内容
        if (importTalks) {
            // 获取非目标章节的对话
            const existingTalks = (this.bookdata.talks || []).filter(talk => talk.chapter_id !== targetChapterId);
            console.log(`清空章节 ${targetChapterId} 的内容`);

            // 获取当前最大对话ID
            const maxTalkId = Math.max(...(this.bookdata.talks || []).map(t => t.id), 0);
            let nextTalkId = maxTalkId + 1;

            const newTalks = jsondata.talks.map((talk, index) => {
                let newTalk = { ...talk };
                
                // 分配新的对话ID
                newTalk.id = nextTalkId++;
                
                // 检查该对话的角色是否需要映射
                const roleMap = roleMapping.get(talk.role_id);
                const originalRole = importRoleMap.get(talk.role_id);
                
                if (roleMap) {
                    // 如果角色需要映射，使用映射后的ID和ismain
                    console.log(`对话ID ${newTalk.id}(原ID ${talk.id}): 角色 "${originalRole?.name}" (ID: ${talk.role_id}) 映射到 "${roleMap.name}" (ID: ${roleMap.id})`);
                    newTalk.role_id = roleMap.id;
                    newTalk.role_ismain = roleMap.ismain;
                } else {
                    // 如果角色不需要映射，保持原样
                    const roleName = talk.role_id === 0 ? "旁白" : originalRole?.name;
                    console.log(`对话ID ${newTalk.id}(原ID ${talk.id}): 保持原角色 "${roleName}" (ID: ${talk.role_id})`);
                }

                // 设置章节ID
                newTalk.chapter_id = targetChapterId;
                
                // 设置order
                newTalk.order = index + 1;
                
                return newTalk;
            });

            // 检查是否有角色ID不存在的情况
            const allRoleIds = new Set([
                ...this.bookdata.roles.map(r => r.id),
                ...newTalks.map(t => t.role_id)
            ]);
            
            console.log('最终角色ID列表:', Array.from(allRoleIds));
            console.log('当前编辑器中的角色:', this.bookdata.roles.map(r => `${r.name}(${r.id})`));
            
            // 验证每个对话的角色ID是否有效
            newTalks.forEach(talk => {
                if (talk.role_id !== 0) { // 跳过对旁白角色的验证
                    const role = this.bookdata.roles.find(r => r.id === talk.role_id);
                    if (!role) {
                        console.error(`警告: 对话ID ${talk.id} 使用了不存在的角色ID ${talk.role_id}`);
                    }
                }
            });

            this.bookdata.talks = [...existingTalks, ...newTalks];
        }

        // 确保有chapters
        if (!this.bookdata.chapters) {
            this.bookdata.chapters = [{"id":1,"title":"第1章" , "content":[] , "notes":""}];
        }

        this.save();
        
        // 导入完成后切换到目标章节
        if (importTalks) {
            this.currentChapterNumber = targetChapterId;
        }
    }

    @action
    loadBookData()
    {
        const bookinfo = { "author":"Easy" , "name":"未命名小剧场·第一卷" , "detail":"有趣的对话体小说" , "link":"https://weibo.com/Easy" };

        const chapters = [
        {"id":1,"title":"第1章" , "content":[] , "notes":""},
        {"id":2,"title":"第2章" , "content":[] , "notes":""},
        {"id":3,"title":"第3章" , "content":[] , "notes":""},
        {"id":4,"title":"第4章" , "content":[] , "notes":""},
        ];

        const roles = [
        {"id":0,"name":"旁白","base64":"","ismain":false}
        ];

        this.bookdata.bookinfo = bookinfo;
        this.bookdata.chapters = chapters;
        this.bookdata.roles = roles;
        this.bookdata.talks = [];

    }

    @computed get filteredChapters()
    {
        if( this.chapter_filter.length < 1 ) return this.bookdata.chapters;
        else
        return this.bookdata.chapters.filter( item => item.title.indexOf( this.chapter_filter ) >= 0 );
    }

    @computed get currentChapter()
    {
      
      return this.bookdata.chapters && this.bookdata.chapters.filter( item => item.id == this.currentChapterNumber )[0];
    }

    set currentChapter( id )
    {
      this.currentChapterNumber = id ;
      this.preview_talk_id = 0; // 重置预览状态
      this.show_preview = true; // 确保预览面板可见
      console.log( JSON.stringify(this.currentChapter));
    }

    @action
    importH2Book(data) {
        // 验证数据格式
        if (!data.meta || !data.roles || !data.talks) {
            throw new Error('无效的h2book格式');
        }

        // 获取当前最大ID
        const maxRoleId = parseInt(this.maxid(this.bookdata.roles)) + 1;
        const maxTalkId = parseInt(this.maxid(this.bookdata.talks)) + 1;
        const maxOrder = parseInt(this.maxid(this.bookdata.talks, 'order')) + 1;

        // 导入角色
        const roleIdMap = new Map();
        data.roles.forEach((role, index) => {
            const newRole = {
                ...role,
                id: maxRoleId + index,
                note: role.note || role.name,
                isstar: role.isstar || false
            };
            roleIdMap.set(role.id, newRole.id);
            this.bookdata.roles.push(newRole);
        });

        // 导入对话
        data.talks.forEach((talk, index) => {
            const newTalk = {
                ...talk,
                id: maxTalkId + index,
                chapter_id: this.currentChapterNumber,
                role_id: roleIdMap.get(talk.role_id) || talk.role_id,
                order: maxOrder + index
            };
            this.bookdata.talks.push(newTalk);
        });

        // 保存更改
        this.save();
    }

    @action
    clearCurrentChapter() {
        // 过滤掉当前章节的对话
        this.bookdata.talks = this.bookdata.talks.filter(talk => talk.chapter_id !== this.currentChapterNumber);
        this.save();
    }

    @action
    clearAllChapters() {
        // 清空所有对话
        this.bookdata.talks = [];
        this.save();
    }

    @action
    updateCurrentTalks(newTalks) {
        if (!this.currentChapter) return;
        
        // 保留其他章节的对话
        const otherChapterTalks = this.bookdata.talks.filter(talk => talk.chapter_id !== this.currentChapterNumber);
        
        // 添加当前章节的新对话，并确保每个对话都有必要的字段
        const updatedTalks = newTalks.map(talk => ({
            ...talk,
            chapter_id: this.currentChapterNumber,
            type: talk.role_id > 0 ? "role" : "aside",
            role_ismain: talk.role_id > 0 ? this.getRole(talk.role_id)?.ismain || false : false
        }));

        // 使用 action 更新 bookdata.talks
        this.bookdata = {
            ...this.bookdata,
            talks: [...otherChapterTalks, ...updatedTalks]
        };
        
        // 强制更新预览
        this.preview_talk_id = 0;
        
        this.save();
        this.toast("对话数据已更新");
    }

}

export default new AppState();