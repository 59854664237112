import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import AvatarSquare from '../component/AvatarSquare';
import { Menu, MenuItem,MenuDivider, Button, ControlGroup,InputGroup, EditableText, Tooltip, Position, TextArea  } from "@blueprintjs/core";
import { ContextMenuTarget } from "@blueprintjs/core/lib/esnext/components/context-menu/contextMenuTarget";


@withRouter
@translate()
@inject("store")
@observer
@ContextMenuTarget
export default class TalkItemEditable extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }
    update_talk = ( id , text ) =>
    {
        const store = this.props.store;
        store.updateTalk( id , text );
    };

    remove_talk = ( id ) =>
    {
        const store = this.props.store;
        if( window.confirm( "确定要删除此台词？删除后不可恢复" ) )
            store.removeTalk( id );
    }

    update_role = ( talk ) =>
    {
        const store = this.props.store;
        let next_role_id = store.nextRole(talk.role_id );
        if(  next_role_id == 0 ) next_role_id = store.nextRole(next_role_id );
        if( !next_role_id ) next_role_id = 0;
        store.updateTalkRole( talk.id , next_role_id);
        // if( props.onUpdated ) props.onUpdated();
    };

    set_focused_talk = ( id ) =>
    {
        const store = this.props.store;
        if( store.focused_talk_id == id ) store.focused_talk_id = 0;
        else store.focused_talk_id = id;
    }

    set_preview_talk = ( id ) =>
    {
        const store = this.props.store;
        store.preview_talk_id= id;
        store.show_preview = true;
        // setTimeout(() => {
        //     store.show_preview = true;
        // }, 0);
        

    }

    renderContextMenu() 
    {
        // return a single element, or nothing to use default browser behavior
        return (
            <Menu>
                <MenuItem onClick={()=>this.set_preview_talk( this.props.data.id )} text="预览到此处" />
                <MenuDivider/>
                <MenuItem onClick={()=>this.remove_talk( this.props.data.id )} text="删除此条台词" />
            </Menu>
        );
    }



    render()
    {
        const store = this.props.store;
        const props = this.props;

        if( !props.data ) return null;
        const talk = props.data;
        const role = store.getRole( talk.role_id );
        if( !role ){
            console.log("role not found",JSON.stringify(talk,null,2));
            return null;
        } 
        const role_class = talk.role_ismain ? " role main " : " role notmain";
        const active_class = store.focused_talk_id == talk.id ? " active " : "";
        
        return talk.type == 'role' 
        ?
            <div className={"talk-list-item-edt"+role_class+(store.focused_talk_id == talk.id ? " active " : "")} onClick={()=>this.set_focused_talk(talk.id)}>
            <div className="avatarside">
                <AvatarSquare data={role} className="s48" onClick={()=>this.update_role(talk)}/>
            </div>
            <div className="contentside">
                <div className="name">{role?.name}</div>
                <div className="text">
                <EditableText 
                    onConfirm={(text)=>this.update_talk( talk.id , text )}
                    // onChange={(text)=>store.saveBookInfo( text , 'link'  , false )}
                    defaultValue={talk?.text}
                    placeholder="不能为空"
                    multiline={true}
                    className="inplace-edit"
                    />
                       

                </div>
            </div>
            
            </div>
           
        :
        
        <div className={"talk-list-item-edt aside"+(store.focused_talk_id == talk.id ? " active " : "")} onClick={()=>this.set_focused_talk(talk.id)}><div className="aside-text"><EditableText 
        onConfirm={(text)=>this.update_talk( talk.id , text )}
        // onChange={(text)=>store.saveBookInfo( text , 'link'  , false )}
        defaultValue={talk.text}
        placeholder="不能为空"
        multiline={true}
        className="inplace-edit"
        /></div> </div>
       ;
    }
}