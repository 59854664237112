import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Dialog, Checkbox, Button, FormGroup, HTMLSelect } from "@blueprintjs/core";

@inject("store")
@observer
export default class ImportDialog extends Component {
    state = {
        isOpen: false,
        importMeta: true,
        importRoles: true,
        correctRoleIds: true,
        importTalks: true,
        targetChapterId: 1,
        bookData: null
    };

    show = (bookData) => {
        this.setState({ 
            isOpen: true,
            bookData,
            importMeta: true,
            importRoles: true,
            correctRoleIds: true,
            importTalks: true,
            targetChapterId: this.props.store.currentChapterNumber || 1
        });
    };

    handleClose = () => {
        this.setState({ isOpen: false });
    };

    handleImport = () => {
        const { store } = this.props;
        const { bookData, importMeta, importRoles, correctRoleIds, importTalks, targetChapterId } = this.state;
        
        store.importBookData(bookData, {
            importMeta,
            importRoles,
            correctRoleIds,
            importTalks,
            targetChapterId: parseInt(targetChapterId)
        });
        
        this.handleClose();
    };

    render() {
        const { isOpen, importMeta, importRoles, correctRoleIds, importTalks, targetChapterId } = this.state;
        const { store } = this.props;
        const chapters = store.bookdata.chapters || [];

        return (
            <Dialog
                isOpen={isOpen}
                onClose={this.handleClose}
                title="导入选项"
                style={{ width: '400px' }}
            >
                <div className="bp3-dialog-body">
                    <FormGroup>
                        <Checkbox
                            checked={importMeta}
                            label="导入基本信息（书名、作者等）"
                            onChange={e => this.setState({ importMeta: e.target.checked })}
                        />
                        <Checkbox
                            checked={importRoles}
                            label="导入角色"
                            onChange={e => this.setState({ importRoles: e.target.checked })}
                        />
                        <Checkbox
                            checked={correctRoleIds}
                            disabled={!importRoles}
                            label="自动校正重复角色ID"
                            onChange={e => this.setState({ correctRoleIds: e.target.checked })}
                        />
                        <Checkbox
                            checked={importTalks}
                            label="导入对话内容"
                            onChange={e => this.setState({ importTalks: e.target.checked })}
                        />
                        {importTalks && (
                            <FormGroup label="导入到章节">
                                <HTMLSelect
                                    value={targetChapterId}
                                    onChange={e => this.setState({ targetChapterId: e.target.value })}
                                    options={chapters.map(chapter => ({
                                        label: chapter.title,
                                        value: chapter.id
                                    }))}
                                />
                            </FormGroup>
                        )}
                    </FormGroup>
                </div>
                <div className="bp3-dialog-footer">
                    <div className="bp3-dialog-footer-actions">
                        <Button onClick={this.handleClose}>取消</Button>
                        <Button intent="primary" onClick={this.handleImport}>导入</Button>
                    </div>
                </div>
            </Dialog>
        );
    }
} 