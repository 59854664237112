import React, { useState, useEffect } from 'react';
import { Dialog, Classes, Button, TextArea, Intent } from "@blueprintjs/core";
import { observer } from 'mobx-react';

const DebugDialog = observer(({ isOpen, onClose, store }) => {
    const [jsonText, setJsonText] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (isOpen && store.currentChapter) {
            const talks = store.currentTalks || [];
            setJsonText(JSON.stringify(talks, null, 2));
            setError('');
        }
    }, [isOpen, store.currentChapter]);

    const handleSave = () => {
        try {
            const parsedJson = JSON.parse(jsonText);
            if (!Array.isArray(parsedJson)) {
                throw new Error('数据必须是数组格式');
            }
            
            // 验证每个对话项是否包含必要的字段
            parsedJson.forEach((talk, index) => {
                if (!talk.hasOwnProperty('id') || !talk.hasOwnProperty('role_id') || !talk.hasOwnProperty('text')) {
                    throw new Error(`第 ${index + 1} 条对话缺少必要字段 (id, role_id, text)`);
                }
            });

            store.updateCurrentTalks(parsedJson);
            setError('');
            onClose();
        } catch (e) {
            setError('JSON 格式错误: ' + e.message);
        }
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            title="调试对话数据"
            className="debug-dialog"
        >
            <div className={Classes.DIALOG_BODY}>
                <TextArea
                    fill={true}
                    value={jsonText}
                    onChange={e => setJsonText(e.target.value)}
                    rows={20}
                    style={{ fontFamily: 'monospace' }}
                />
                {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={onClose}>取消</Button>
                    <Button intent={Intent.PRIMARY} onClick={handleSave}>保存</Button>
                </div>
            </div>
        </Dialog>
    );
});

export default DebugDialog; 