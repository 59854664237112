import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import { Button } from "@blueprintjs/core";
import Dropzone from 'react-dropzone';
import ImportDialog from './ImportDialog';

@withRouter
@translate()
@inject("store")
@observer
export default class OpenFile extends Component
{
    constructor(props) 
    {
        super(props);
        this.drop_ref = React.createRef();
        this.importDialogRef = React.createRef();
    }
    
    componentDidMount()
    {
        // 将对话框引用保存到store中
        this.props.store.importDialog = this.importDialogRef.current;
    }

    onDropped( files )
    {
        const reader = new FileReader();
        reader.addEventListener("loadend", (event) => {
            const jsondata = JSON.parse( event.target.result );
            if( jsondata )
            {
                this.props.store.setBookData( jsondata );  
                this.props.store.local_file_path = false;
            } 
        });
        reader.readAsText(files[0]);
    }

    onOpen()
    {
        this.drop_ref.current.open();
    }

    render()
    {
        const store = this.props.store;
        // const props = this.props;
        
        // return <div><Button icon="document-open" minimal={true} onClick={()=>this.onOpen()} />
        // <div className="dropzone">
        //     <Dropzone 
        //         //accept=".h2zip" 
        //         //multiple={false} 
        //         //ref={this.drop_ref} 
        //         //onDrop={(accepted, rejected) => { this.onDropped( accepted ) }}
        //     />
        // </div>
        // </div>;
        return (
            <>
                <Dropzone 
                accept=".h2doc,.h2book"  
                maxSize={1024*1024*10}
                multiple={false} 
                ref={this.drop_ref} 
                onDrop={acceptedFiles => this.onDropped(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                  <div {...getRootProps()}>
                      {/*  */}
                      <input {...getInputProps()} />
                      <Button icon="document-open" minimal={true}  />
                  </div>
                  

                )}
              </Dropzone>
              <ImportDialog ref={this.importDialogRef} />
            </>
        );
    }
}