import React from 'react';
import { Dialog, Button, Intent, Classes, RadioGroup, Radio } from "@blueprintjs/core";
import { observer, inject } from 'mobx-react';

const ClearDialog = inject("store")(observer(({ isOpen, onClose, store }) => {
    const [clearType, setClearType] = React.useState('current');

    const handleClear = () => {
        if (clearType === 'current') {
            store.clearCurrentChapter();
        } else {
            store.clearAllChapters();
        }
        store.toast('清空成功');
        onClose();
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            title="清空内容"
            style={{ width: '400px' }}
        >
            <div className={Classes.DIALOG_BODY}>
                <RadioGroup
                    label="选择清空范围"
                    onChange={e => setClearType(e.target.value)}
                    selectedValue={clearType}
                >
                    <Radio label="仅清空当前章节" value="current" />
                    <Radio label="清空所有章节" value="all" />
                </RadioGroup>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button 
                        onClick={onClose}
                        style={{ marginRight: '10px' }}
                    >
                        取消
                    </Button>
                    <Button
                        intent={Intent.DANGER}
                        onClick={handleClear}
                    >
                        清空
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}));

export default ClearDialog; 