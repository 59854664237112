import React, { useState, useEffect, useRef } from 'react';
import { Dialog, FormGroup, InputGroup, FileInput, Button, Intent, Classes, HTMLSelect, TextArea, Collapse } from "@blueprintjs/core";
import { observer, inject } from 'mobx-react';
import OpenAI from 'openai';

const H2BOOK_SCHEMA = {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "type": "object",
    "properties": {
        "meta": {
            "type": "object",
            "properties": {
                "author": { "type": "string" },
                "name": { "type": "string" },
                "detail": { "type": "string" },
                "link": { "type": "string" }
            },
            "required": ["author", "name", "detail", "link"]
        },
        "roles": {
            "type": "array",
            "items": {
                "type": "object",
                "properties": {
                    "id": { "type": "integer" },
                    "name": { "type": "string" },
                    "base64": { "type": ["string", "null"] },
                    "ismain": { "type": "boolean" },
                    "isstar": { "type": ["boolean", "null"] },
                    "note": { "type": ["string", "null"] }
                },
                "required": ["id", "name", "ismain"]
            }
        },
        "talks": {
            "type": "array",
            "items": {
                "type": "object",
                "properties": {
                    "id": { "type": "integer" },
                    "chapter_id": { "type": "integer" },
                    "type": {
                        "type": "string",
                        "enum": ["aside", "role"]
                    },
                    "role_id": { "type": "integer" },
                    "role_ismain": { "type": "boolean" },
                    "order": { "type": "integer" },
                    "text": { "type": "string" }
                },
                "required": ["id", "chapter_id", "type", "role_id", "role_ismain", "order", "text"]
            }
        }
    },
    "required": ["meta", "roles", "talks"]
};

const API_PROVIDERS = {
    api2d: {
        label: 'API2D OpenAI',
        baseUrl: 'https://oa.api2d.net',
        model: 'gpt-4o-mini'
    },
    // api2d_claude: {
    //     label: 'API2D Claude',
    //     baseUrl: 'https://oa.api2d.net/claude',
    //     model: 'claude-3-5-sonnet'
    // },
    deepseek_v3: {
        label: 'DeepSeek V3',
        baseUrl: 'https://api.deepseek.com',
        model: 'deepseek-chat'
    },
    deepseek_r1: {
        label: 'DeepSeek R1',
        baseUrl: 'https://api.deepseek.com',
        model: 'deepseek-reasoner'
    },
    siliconflow_v3: {
        label: 'SiliconFlow V3',
        baseUrl: 'https://api.siliconflow.com',
        model: 'deepseek-ai/DeepSeek-V3'
    },
    siliconflow_r1: {
        label: 'SiliconFlow R1',
        baseUrl: 'https://api.siliconflow.com',
        model: 'deepseek-ai/DeepSeek-R1'
    },
};

const STORAGE_KEYS = {
    API_KEY: 'h2editor_ai_api_key',
    API_BASE_URL: 'h2editor_ai_base_url',
    MODEL: 'h2editor_ai_model',
    PROVIDER: 'h2editor_ai_provider',
    PROMPT: 'h2editor_ai_prompt',
    MAX_TOKENS: 'h2editor_ai_max_tokens',
    USE_JSON_OBJECT: 'h2editor_ai_use_json_object'
};

const DEFAULT_PROMPT = `你是世界一流的编剧。请根据输入的文本，将其改写为 Chat Fiction 形式（采用类似依次显示的聊天记录）。

已有角色列表如下（请直接使用这些角色的id和ismain值）：
{{roles}}
未包含在列表的角色，请自行按Schema中的格式创建（使用max +1 作为 role id）并将其添加到 roles 数组中。

请按照以下规则处理：

1. 首先确定主角，其身份影响 roles 元素中的 ismain 和 talks 元素中的 role_ismain
2. 接着确认原文中包含哪些角色，并根据剧情确定各自的台词
3. 适配一些聊天对话特有的形式，
    - 无话可说时，台词可以是「…」
    - 一些设备比如手机和电脑，也可以作为「角色」参与对话，比如：手机：「您有23条新消息」;
    - 角色的心理活动和行动也改用对话形式表现，但用()包裹动作，比如：（揉了揉眼睛），注意括号内的内容无需再包含主语
4. 然后还原场景，将描述性文字留给旁白（role_id=0, type=aside）
5. 对于原文中的对话，原样保留
6. 确保每条记录只包含一句话，如果原文包含多句话，则拆分为多条记录。旁白也按此处理。如果被拆开的句子中有代指前一句人物的代词，则需要还原为对应的角色名字。
7. 所有对话的chapter_id必须设置为{{chapterId}}`;

const AIImportDialog = inject("store")(observer(({ isOpen, onClose, store }) => {
    // 从localStorage初始化状态
    const [apiKey, setApiKey] = useState(() => localStorage.getItem(STORAGE_KEYS.API_KEY) || '');
    const [apiBaseUrl, setApiBaseUrl] = useState(() => localStorage.getItem(STORAGE_KEYS.API_BASE_URL) || API_PROVIDERS.api2d.baseUrl);
    const [model, setModel] = useState(() => localStorage.getItem(STORAGE_KEYS.MODEL) || API_PROVIDERS.api2d.model);
    const [provider, setProvider] = useState(() => localStorage.getItem(STORAGE_KEYS.PROVIDER) || 'api2d');
    const [maxTokens, setMaxTokens] = useState(() => localStorage.getItem(STORAGE_KEYS.MAX_TOKENS) || '');
    const [useJsonObject, setUseJsonObject] = useState(() => localStorage.getItem(STORAGE_KEYS.USE_JSON_OBJECT) === 'true');
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [importCompleted, setImportCompleted] = useState(false);
    const [selectedChapter, setSelectedChapter] = useState(() => store.currentChapterNumber);
    const [logs, setLogs] = useState([]);
    const logsEndRef = useRef(null);
    const [prompt, setPrompt] = useState(() => localStorage.getItem(STORAGE_KEYS.PROMPT) || DEFAULT_PROMPT);

    // 当值变化时保存到localStorage
    useEffect(() => {
        localStorage.setItem(STORAGE_KEYS.API_KEY, apiKey);
    }, [apiKey]);

    useEffect(() => {
        localStorage.setItem(STORAGE_KEYS.API_BASE_URL, apiBaseUrl);
    }, [apiBaseUrl]);

    useEffect(() => {
        localStorage.setItem(STORAGE_KEYS.MODEL, model);
    }, [model]);

    useEffect(() => {
        localStorage.setItem(STORAGE_KEYS.MAX_TOKENS, maxTokens);
    }, [maxTokens]);

    useEffect(() => {
        localStorage.setItem(STORAGE_KEYS.USE_JSON_OBJECT, useJsonObject);
    }, [useJsonObject]);

    // 当供应商变化时更新相关设置
    const handleProviderChange = (e) => {
        const newProvider = e.target.value;
        setProvider(newProvider);
        setApiBaseUrl(API_PROVIDERS[newProvider].baseUrl);
        setModel(API_PROVIDERS[newProvider].model);
        localStorage.setItem(STORAGE_KEYS.PROVIDER, newProvider);
    };

    // 当对话框打开时，更新章节选择
    useEffect(() => {
        if (isOpen) {
            setSelectedChapter(store.currentChapterNumber);
        }
    }, [isOpen, store.currentChapterNumber]);

    // 当提示词变化时保存到localStorage
    useEffect(() => {
        localStorage.setItem(STORAGE_KEYS.PROMPT, prompt);
    }, [prompt]);

    const addLog = (message) => {
        // 如果是最后一个日志是普通文本（不是以✓或❌开头），则更新它而不是添加新行
        setLogs(prev => {
            if (prev.length > 0 && 
                !prev[prev.length - 1].startsWith('✓') && 
                !prev[prev.length - 1].startsWith('❌') &&
                !prev[prev.length - 1].startsWith('⟳')) {
                return [...prev.slice(0, -1), prev[prev.length - 1] + message];
            }
            return [...prev, message];
        });
    };

    // 自动滚动日志到底部
    useEffect(() => {
        if (logsEndRef.current) {
            logsEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [logs]);

    // 当对话框打开时清空日志
    useEffect(() => {
        if (isOpen) {
            setLogs([]);
            setImportCompleted(false);
        }
    }, [isOpen]);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && (selectedFile.type === 'text/plain' || selectedFile.type === 'text/markdown')) {
            setFile(selectedFile);
        } else {
            store.toast('请选择.txt或.md文件');
        }
    };

    const handleResetPrompt = () => {
        setPrompt(DEFAULT_PROMPT);
    };

    const getFormattedPrompt = () => {
        const existingRoles = store.bookdata.roles.map(role => ({
            id: role.id,
            name: role.name,
            ismain: role.ismain,
            note: role.note
        }));

        const formatInstructions = `
输出格式要求：
1. meta部分可以简单设置，比如：
{
    "author": "AI助手",
    "name": "导入的对话",
    "detail": "从文本导入的对话",
    "link": ""
}

2. roles部分只需要包含新创建的角色（已有角色无需包含）

3. talks部分包含所有对话，要求：
- id从1开始递增
- chapter_id统一为${selectedChapter}
- order从1开始递增
- role_id必须是已有角色的id或新创建角色的id
- role_ismain要与对应角色的ismain值保持一致

你必须严格按照以下JSON Schema来输出JSON，注意是输出JSON，而不是JSON Schema。
JSON Schema: ${JSON.stringify(H2BOOK_SCHEMA, null, 2)}
JSON:
`;

        return prompt
            .replace('{{roles}}', JSON.stringify(existingRoles, null, 2))
            .replace('{{chapterId}}', selectedChapter) + 
            '\n\n' + formatInstructions;
    };

    const handleImport = async () => {
        if (!file || !apiKey) {
            store.toast('请选择文件并填写API Key');
            return;
        }

        setIsLoading(true);
        setLogs([]);

        let openai;
        try {
            const text = await file.text();
            addLog("✓ 文件读取完成");

            // 初始化 OpenAI 客户端
            openai = new OpenAI({
                apiKey: apiKey,
                baseURL: apiBaseUrl,
                dangerouslyAllowBrowser: true
            });

            addLog("⟳ 正在调用AI进行处理...");
            addLog(""); // 添加一个空行用于流式输出

            const stream = await openai.chat.completions.create({
                model: model,
                messages: [
                    {
                        role: 'system',
                        content: getFormattedPrompt()
                    },
                    {
                        role: 'user',
                        content: text
                    }
                ],
                stream: true,
                ...(useJsonObject ? { response_format: { type: "json_object" } } : {}),
                ...(maxTokens ? { max_tokens: parseInt(maxTokens) } : {})
            });

            let responseText = '';
            for await (const chunk of stream) {
                const content = chunk.choices[0]?.delta?.content || '';
                if (content) {
                    responseText += content;
                    addLog(content);
                }
            }

            addLog("✓ AI处理完成，正在解析结果...");
            console.log(responseText);

            // 移除<think>标签及其内容
            responseText = responseText.replace(/<think>[\s\S]*?<\/think>/g, '');
            // 移除markdown代码块标记
            responseText = responseText.replace(/```[^\n]*\n|```$/g, '');

            // 从响应文本中提取JSON
            const jsonMatch = responseText.match(/\{[\s\S]*\}/);
            if (!jsonMatch) {
                throw new Error('无法从AI响应中提取JSON数据');
            }

            const parsedResult = JSON.parse(jsonMatch[0]);
            addLog("✓ JSON解析完成");

            // 处理R1格式的输出
            if (parsedResult.talks && parsedResult.talks[0] && 'content' in parsedResult.talks[0]) {
                parsedResult.talks = parsedResult.talks.map(talk => ({
                    ...talk,
                    text: talk.content,
                    role_ismain: store.bookdata.roles.find(r => r.id === talk.role_id)?.ismain || false
                }));
            }

            // 强制修正所有对话的chapter_id
            addLog("⟳ 正在设置章节ID...");
            parsedResult.talks = parsedResult.talks.map((talk, index) => ({
                ...talk,
                chapter_id: parseInt(selectedChapter),
                id: index + 1,  // 确保id从1开始递增
                order: index + 1  // 确保order从1开始递增
            }));
            addLog("✓ 章节ID设置完成");

            // 导入前先切换到目标章节
            store.currentChapterNumber = parseInt(selectedChapter);
            
            store.importH2Book(parsedResult);
            addLog("✓ 导入成功！");
            store.toast('导入成功');
            setImportCompleted(true);
            
        } catch (error) {
            addLog(`❌ 错误: ${error.message}`);
            store.toast(`导入失败: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            title="AI导入"
            style={{ width: '500px' }}
        >
            <div className={Classes.DIALOG_BODY}>
                <FormGroup 
                    label="文件"
                    labelFor="file-input"
                    style={{ marginBottom: '15px' }}
                >
                    <FileInput
                        id="file-input"
                        text={file ? file.name : "选择文件..."}
                        onInputChange={handleFileChange}
                        accept=".txt,.md"
                        fill={true}
                    />
                </FormGroup>
                <FormGroup 
                    label="导入到章节"
                    labelFor="chapter-select"
                    style={{ marginBottom: '15px' }}
                >
                    <HTMLSelect
                        id="chapter-select"
                        value={selectedChapter}
                        onChange={(e) => setSelectedChapter(e.target.value)}
                        fill={true}
                    >
                        {store.bookdata.chapters && store.bookdata.chapters.map(chapter => (
                            <option key={chapter.id} value={chapter.id}>
                                {chapter.title}
                            </option>
                        ))}
                    </HTMLSelect>
                </FormGroup>
                <FormGroup 
                    label="API供应商"
                    labelFor="provider-select"
                    style={{ marginBottom: '15px' }}
                >
                    <HTMLSelect
                        id="provider-select"
                        value={provider}
                        onChange={handleProviderChange}
                        fill={true}
                    >
                        {Object.entries(API_PROVIDERS).map(([key, value]) => (
                            <option key={key} value={key}>
                                {value.label}
                            </option>
                        ))}
                    </HTMLSelect>
                </FormGroup>
                <FormGroup 
                    label="API Key"
                    labelFor="api-key-input"
                    style={{ marginBottom: '15px' }}
                >
                    <InputGroup
                        id="api-key-input"
                        type="password"
                        value={apiKey}
                        onChange={(e) => setApiKey(e.target.value)}
                        placeholder="输入OpenAI API Key"
                        fill={true}
                    />
                </FormGroup>
                
                <Button
                    minimal={true}
                    onClick={() => setShowAdvanced(!showAdvanced)}
                    rightIcon={showAdvanced ? "chevron-up" : "chevron-down"}
                    style={{ marginBottom: '10px' }}
                >
                    高级设置
                </Button>

                <Collapse isOpen={showAdvanced}>
                    <FormGroup 
                        label="API Base URL"
                        labelFor="api-base-url-input"
                        style={{ marginBottom: '15px' }}
                    >
                        <InputGroup
                            id="api-base-url-input"
                            value={apiBaseUrl}
                            onChange={(e) => setApiBaseUrl(e.target.value)}
                            placeholder="输入API Base URL"
                            fill={true}
                        />
                    </FormGroup>
                    <FormGroup 
                        label="Model"
                        labelFor="model-input"
                        style={{ marginBottom: '15px' }}
                    >
                        <InputGroup
                            id="model-input"
                            value={model}
                            onChange={(e) => setModel(e.target.value)}
                            placeholder="输入模型名称"
                            fill={true}
                        />
                    </FormGroup>
                    <FormGroup 
                        label="Max Tokens"
                        labelFor="max-tokens-input"
                        style={{ marginBottom: '15px' }}
                    >
                        <InputGroup
                            id="max-tokens-input"
                            type="number"
                            value={maxTokens}
                            onChange={(e) => setMaxTokens(e.target.value)}
                            placeholder="输入最大token数"
                            fill={true}
                        />
                    </FormGroup>
                    <FormGroup
                        label="使用JSON Object格式"
                        labelFor="use-json-object-input"
                        style={{ marginBottom: '15px' }}
                    >
                        <div className={Classes.CHECKBOX}>
                            <input
                                id="use-json-object-input"
                                type="checkbox"
                                checked={useJsonObject}
                                onChange={(e) => setUseJsonObject(e.target.checked)}
                            />
                            <span>强制使用 JSON Object 响应格式（部分模型可能不支持）</span>
                        </div>
                    </FormGroup>
                    <FormGroup 
                        label={
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span>提示词</span>
                                <Button
                                    small={true}
                                    minimal={true}
                                    icon="reset"
                                    onClick={handleResetPrompt}
                                >
                                    重置
                                </Button>
                            </div>
                        }
                        labelFor="prompt-input"
                        style={{ marginBottom: '15px' }}
                    >
                        <TextArea
                            id="prompt-input"
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}
                            fill={true}
                            rows={10}
                            style={{ fontFamily: 'monospace', fontSize: '12px' }}
                        />
                    </FormGroup>
                </Collapse>

                <FormGroup 
                    label="处理日志"
                    style={{ marginBottom: '15px' }}
                >
                    <div 
                        className={Classes.INPUT}
                        style={{ 
                            height: '100px', 
                            overflowY: 'auto',
                            padding: '10px',
                            fontFamily: 'monospace',
                            fontSize: '12px',
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-all'
                        }}
                    >
                        {logs.map((log, index) => (
                            <div key={index}>{log}</div>
                        ))}
                        <div ref={logsEndRef} />
                    </div>
                </FormGroup>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    {!importCompleted && (
                        <Button 
                            onClick={onClose}
                            style={{ marginRight: '10px' }}
                            disabled={isLoading}
                        >
                            取消
                        </Button>
                    )}
                    <Button
                        intent={Intent.PRIMARY}
                        onClick={importCompleted ? onClose : handleImport}
                        loading={isLoading}
                    >
                        {importCompleted ? '关闭' : '导入'}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}));

export default AIImportDialog; 