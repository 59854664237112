import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import { Button, FormGroup,InputGroup, Switch, Overlay  } from "@blueprintjs/core";
import Files from "react-butterfiles";
import ImageCropper from '../util/ImageCropper';
import resize from '../util/resize';

@withRouter
@translate()
@inject("store")
@observer
export default class AvatarUploadButton extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    componentDidMount()
    {
        const props = this.props;
        if( props.editing_role )
        {
            this.setRole( role );
        }
    }

    setRole( role )
    {
        this.setState(
        {
            "isOverLay":true,
            "isMain":role.ismain,
            "uid":role.id,
            "uname":role.name,
            "unote":role.note,
            "ufiles":[{"src":{"base64":role.base64}}],
            "uerrors":[],
            "tempImageData": null
        }
        );
    }

    componentDidUpdate(prevProps)
    {
        if (this.props.editing_role !== prevProps.editing_role) 
        {
            if( this.props.editing_role )
                this.setRole( this.props.editing_role );
            // console.log( "now id" , this.props.current_talk_id );
        }
    }

    state = {
        "isOverLay":false,
        "isCropperOverlay": false,
        "isMain":false,
        "uid":0,
        "uname":"",
        "unote":"",
        "ufiles":[],
        "uerrors":[],
        "tempImageData": null,
        "imageLoaded": false
    };

    set( name, value )
    {
        let o = this.state;
        o[name] = value;
        this.setState( o );
    }

    handleUpload = ( files ) =>
    {
        // 预加载图片
        const img = new Image();
        img.onload = () => {
            this.setState({
                "tempImageData": files[0].src.base64,
                "isCropperOverlay": true,
                "imageLoaded": true
            });
        };
        img.src = files[0].src.base64;
    }

    handleCropComplete = (croppedImage) => {
        this.setState({
            "ufiles": [{src: {base64: croppedImage}}],
            "isCropperOverlay": false,
            "tempImageData": null,
            "imageLoaded": false
        });
    }

    async createRole()
    {
        const store = this.props.store;
        const state = this.state;

        if( state.uname.length < 1 )
        {
            store.toast("角色姓名不能为空");
            return false;
        } 

        let base64 = "";
        if( state.ufiles && state.ufiles[0] && state.ufiles[0].src && state.ufiles[0].src.base64 )
        {
            base64 = await resize( state.ufiles[0].src.base64 , 256 , 256 );
        }

        let ret = false;

        if( state.uid > 0 )
        {
            ret = store.updateRole( state.uid, state.uname , base64 , state.isMain , state.unote );
        }
        else
        {
            ret = store.createRole( state.uname , base64 , state.isMain , state.unote );
        }

        if( ret )
        {
            this.setState( {
                "isOverLay":false,
                "isCropperOverlay": false,
                "isMain":false,
                "uid":0,
                "uname":"",
                "unote":"",
                "ufiles":[],
                "uerrors":[],
                "tempImageData": null
            });

            if( this.props.onFinished ) this.props.onFinished();
        }
        else
        {
            store.toast("创建角色错误");
        }
    }

    cancel()
    {
        this.set( "isOverLay",false);
        this.set( "isCropperOverlay",false);
        this.set( "tempImageData",null);
        this.set( "imageLoaded",false);
        if( this.props.onFinished ) this.props.onFinished();
    }

    setErrors(e)
    {
        console.log(e);
    }
    
    render()
    {
        const store = this.props.store;
        const props = this.props;
        const state = this.state;

        const style = {
            width: 180,
            height: 180,
            border: "2px lightgray dashed"
        };
        
        return <>
            <div className={props.className} onClick={()=>this.set( 'isOverLay' , true)}>
                <Button icon="new-person" minimal={true} />
            </div>

            {/* 主要编辑窗口 */}
            <Overlay isOpen={state.isOverLay} onClose={()=>this.cancel()}>
                <div className="avatar-overbox">
                    <div className="center-box">
                        <FormGroup
                            helperText="如：可卡"
                            label="角色姓名"
                            labelInfo="在阅读环境显示"
                        >
                            <InputGroup id="text-input" placeholder="必填项" value={state.uname} onChange={(e)=>this.set('uname',e.target.value)} />
                        </FormGroup>
                        <FormGroup
                            helperText="如：可卡·笑，不填写将显示姓名"
                            label="角色备注"
                            labelInfo="在编辑环境显示"
                        >
                            <InputGroup id="text-input" placeholder="选填项" value={state.unote} onChange={(e)=>this.set('unote',e.target.value)} />
                        </FormGroup>
                        <div className="file-row">
                            <Files
                                accept={["image/jpg", "image/jpeg", "image/png"]}
                                convertToBase64={true}
                                maxSize="30mb" 
                                multipleMaxSize="30mb"
                                onSuccess={(e)=>this.handleUpload(e)}
                                onError={(e)=>this.setErrors(e)}
                            >
                                {({ browseFiles, getDropZoneProps }) => (
                                    <>
                                        <label>点击或拖拽头像到下框</label>
                                        <div
                                            onClick={browseFiles}
                                            {...getDropZoneProps({
                                                style: { ...style, cursor: "pointer" }
                                            })}
                                        >
                                            {state.ufiles[0] && (
                                                <img
                                                    style={{ width: "100%" }}
                                                    src={state.ufiles[0].src.base64}
                                                />
                                            )}
                                            {state.uerrors.length > 0 && (
                                                <div>上传出错</div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </Files>
                        </div>
                        
                        <FormGroup
                            helperText="主角头像会出现在左侧"
                            label="角色类型"
                        >
                            <Switch checked={state.isMain} label="是否主角" onChange={(e)=>this.set( "isMain" , e.target.checked)} />
                        </FormGroup>
                        <div className="std-hr" />
                        <FormGroup>
                            <div className="s2row">
                                <Button large={true} minimal={true} onClick={()=>this.cancel()}>取消</Button>
                                <Button large={true} intent="primary" onClick={()=>this.createRole()}>保存</Button>
                            </div>
                        </FormGroup>
                    </div>
                </div>
            </Overlay>

            {/* 裁剪窗口 */}
            <Overlay isOpen={state.isCropperOverlay} onClose={()=>this.set('isCropperOverlay', false)}>
                <div className="avatar-overbox">
                    <div className="center-box cropper-box">
                        <h3 style={{margin: 0, marginBottom: 15, textAlign: 'center'}}>裁剪头像</h3>
                        {state.imageLoaded && (
                            <ImageCropper 
                                aspectRatio={1}
                                minContainerWidth={380}
                                maxContainerWidth={380}
                                minContainerHeight={380}
                                maxContainerHeight={380}
                                dragMode="move"
                                guides={true}
                                center={false}
                                highlight={true}
                                background={true}
                                viewMode={1}
                                initialAspectRatio={1}
                                data={{
                                    y: 0,
                                    x: 0
                                }}
                                ready={(e) => {
                                    // 确保图片完整显示,根据长宽比自适应缩放
                                    const cropper = e.target.cropper;
                                    const containerData = cropper.getContainerData();
                                    const imageData = cropper.getImageData();
                                    
                                    // 计算宽高比
                                    const containerRatio = containerData.width / containerData.height;
                                    const imageRatio = imageData.naturalWidth / imageData.naturalHeight;
                                    
                                    // 根据长宽比决定缩放基准
                                    let scale;
                                    if (imageRatio > containerRatio) {
                                        // 图片较宽,以容器宽度为基准
                                        scale = containerData.width / imageData.naturalWidth;
                                    } else {
                                        // 图片较高,以容器高度为基准
                                        scale = containerData.height / imageData.naturalHeight;
                                    }
                                    
                                    cropper.zoomTo(scale);
                                }}
                            >
                                {({ getImgProps, getDataURL }) => (
                                    <>
                                        <img
                                            {...getImgProps({
                                                src: state.tempImageData,
                                                style: {
                                                    maxWidth: "100%"
                                                }
                                            })}
                                        />
                                        <div className="buttons-row">
                                            <Button onClick={()=>this.set('isCropperOverlay', false)}>取消</Button>
                                            <Button 
                                                intent="primary"
                                                onClick={() => {
                                                    this.handleCropComplete(getDataURL());
                                                }}
                                            >
                                                确认裁剪
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </ImageCropper>
                        )}
                    </div>
                </div>
            </Overlay>
        </>;
    }
}