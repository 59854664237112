import React from 'react';
import { Dialog, Classes, FormGroup, InputGroup, Button, Alert } from "@blueprintjs/core";
import { observer, inject } from 'mobx-react';

const STORAGE_KEY = 'h2editor_upload_config';

@inject("store")
@observer
export default class UploadDialog extends React.Component {
    constructor(props) {
        super(props);
        // 从localStorage读取保存的配置
        const savedConfig = localStorage.getItem(STORAGE_KEY);
        const config = savedConfig ? JSON.parse(savedConfig) : {};
        
        this.state = {
            uploadKey: config.uploadKey || '',
            uploadUrl: config.uploadUrl || '',
            uploading: false,
            showPreviewConfirm: false,
            previewUrl: ''
        };
    }

    handleUpload = async () => {
        const { store } = this.props;
        const { uploadKey, uploadUrl } = this.state;
        
        if (!uploadUrl) {
            store.toast("请填写服务器地址");
            return;
        }

        this.setState({ uploading: true });

        try {
            // 获取当前章节数据
            const chapterData = store.currentChapter;
            if (!chapterData) {
                throw new Error("没有选中的章节");
            }

            // 获取本章的对话内容
            const talks = store.currentTalks;
            if (!talks || talks.length === 0) {
                throw new Error("当前章节没有对话内容");
            }

            // 从talks中抽取用到的roles
            const roles = [];
            talks.forEach(item => {
                if (!store.find_by_id(roles, item.role_id)) {
                    const role = {...store.getRole(item.role_id)};
                    role.note = null;
                    role.isstar = null;
                    roles.push(role);
                }
            });

            // 补充基本信息
            const meta = {...store.bookdata.bookinfo};
            const chapter_name = chapterData.title;
            if (chapter_name.trim().length > 0) {
                meta.name += '·' + chapter_name;
            }

            // 组装h2book格式的数据
            const book = { meta, roles, talks };
            
            // 创建FormData
            const formData = new FormData();
            if (uploadKey) {
                formData.append('upload_key', uploadKey);
            }
            
            // 将数据转换为Blob
            const blob = new Blob([JSON.stringify(book)], { type: 'application/json' });
            formData.append('book', blob, meta.name + '.h2book');

            // 发送请求
            const response = await fetch(uploadUrl, {
                method: 'POST',
                body: formData
            });

            const result = await response.json();

            if (result.code === 0) {
                // 保存配置到localStorage
                localStorage.setItem(STORAGE_KEY, JSON.stringify({
                    uploadKey: this.state.uploadKey,
                    uploadUrl: this.state.uploadUrl
                }));
                
                store.toast("上传成功！");
                
                // 更新bookinfo的link字段
                if (result.data && result.data.url) {
                    const bookUrl = result.data.url;
                    const baseUrl = bookUrl.substring(0, bookUrl.lastIndexOf('/') + 1);
                    const readUrl = baseUrl.replace('/books/', '/read/') + encodeURIComponent(bookUrl);
                    
                    // 先关闭上传对话框
                    this.props.onClose();
                    
                    // 然后显示预览确认对话框
                    this.setState({ 
                        showPreviewConfirm: true,
                        previewUrl: readUrl
                    });
                }
            } else {
                throw new Error(result.message || "上传失败");
            }
        } catch (error) {
            store.toast("上传失败: " + error.message);
        } finally {
            this.setState({ uploading: false });
        }
    }

    handlePreviewConfirm = () => {
        const { previewUrl } = this.state;
        window.open(previewUrl, '_blank');
        this.setState({ showPreviewConfirm: false });
    }

    handlePreviewCancel = () => {
        this.setState({ showPreviewConfirm: false });
    }

    render() {
        const { isOpen, onClose } = this.props;
        const { uploadKey, uploadUrl, uploading, showPreviewConfirm, previewUrl } = this.state;

        return (
            <>
                <Dialog
                    isOpen={isOpen}
                    onClose={onClose}
                    title="发布到服务器"
                    className="upload-dialog"
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup label="上传密钥" labelFor="upload-key">
                            <InputGroup
                                id="upload-key"
                                placeholder="请输入上传密钥...，没有请留空"
                                value={uploadKey}
                                onChange={e => this.setState({ uploadKey: e.target.value })}
                            />
                        </FormGroup>
                        <FormGroup label="服务器地址" labelFor="upload-url">
                            <InputGroup
                                id="upload-url"
                                placeholder="请输入服务器地址...，如：https://xxx.com/api/upload"
                                value={uploadUrl}
                                onChange={e => this.setState({ uploadUrl: e.target.value })}
                            />
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button onClick={onClose}>取消</Button>
                            <Button 
                                intent="primary" 
                                onClick={this.handleUpload}
                                loading={uploading}
                            >
                                确认上传
                            </Button>
                        </div>
                    </div>
                </Dialog>

                <Alert
                    isOpen={showPreviewConfirm}
                    confirmButtonText="打开预览"
                    cancelButtonText="关闭"
                    intent="primary"
                    icon="link"
                    onConfirm={this.handlePreviewConfirm}
                    onCancel={this.handlePreviewCancel}
                >
                    <p>上传成功！是否要在新窗口中打开预览？</p>
                    <p className="bp4-text-muted" style={{ 
                        wordBreak: 'break-all', 
                        wordWrap: 'break-word',
                        maxWidth: '100%'
                    }}>预览地址：{previewUrl}</p>
                </Alert>
            </>
        );
    }
} 