import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import TalkItem from '../component/TalkItem'; 

@withRouter
@translate()
@inject("store")
@observer
export default class TalkList extends Component
{
    constructor(props) 
    {
        super(props);
        this.state = {
            show_talks: [],
            remaining_talks: [],
            end: false
        };
        this.end_ref = React.createRef();
    }

    componentDidMount()
    {
        this.loadData();
    }

    loadData()
    {
        const talks = this.props.talks || [];
        if (this.props.current_talk_id) {
            this.showToId(this.props.current_talk_id);
        } else {
            this.setState({
                show_talks: [],
                remaining_talks: talks,
                end: false
            });
        }
    }

    componentDidUpdate(prevProps)
    {
        // 当对话内容或当前预览ID变化时都需要更新
        if (this.props.talks !== prevProps.talks || 
            this.props.current_talk_id !== prevProps.current_talk_id) 
        {
            const talks = this.props.talks || [];
            
            // 如果current_talk_id变化了，则更新显示
            if (this.props.current_talk_id !== prevProps.current_talk_id) {
                if (this.props.current_talk_id) {
                    this.showToId(this.props.current_talk_id);
                } else {
                    // 如果current_talk_id被清空，重置状态
                    this.setState({
                        show_talks: [],
                        remaining_talks: talks,
                        end: false
                    });
                }
            }
            // 如果talks变化但current_talk_id没变，重新加载数据
            else if (this.props.talks !== prevProps.talks) {
                if (this.props.current_talk_id) {
                    this.showToId(this.props.current_talk_id);
                } else {
                    this.setState({
                        show_talks: [],
                        remaining_talks: talks,
                        end: false
                    });
                }
            }
        }
    }

    showToId = (id) =>
    {
        const talks = this.props.talks || [];
        const index = talks.findIndex(talk => talk.id === id);
        
        if (index >= 0) {
            this.setState({
                show_talks: talks.slice(0, index + 1),
                remaining_talks: talks.slice(index + 1),  // 保存后续所有对话
                end: false
            });
        }
    }

    showOne = () =>
    {
        const { remaining_talks, show_talks } = this.state;
        if (remaining_talks.length < 1) {
            this.setState({ end: true });
            return false;
        }

        const [nextTalk, ...rest] = remaining_talks;
        this.setState({
            remaining_talks: rest,
            show_talks: [...show_talks, nextTalk],
            end: rest.length === 0
        }, () => {
            this.toBottom();
        });

        return nextTalk;
    }

    toBottom = () =>
    {
        this.end_ref.current.scrollIntoView({ behavior: 'smooth' });
    }

    render()
    {
        const { show_talks, end } = this.state;
        const { roles } = this.props;
        
        return (
            <div className="talklist-ro">
                <div className="thelist">
                    {show_talks.length > 0 ? (
                        <>
                            {show_talks.map(item => (
                                <TalkItem roles={roles} key={item.id} data={item} />
                            ))}
                        </>
                    ) : (
                        <div className="guide">
                            点下边的✦开始<span role="img" aria-label="emoji-happy">🤠</span>
                        </div>
                    )}
                    <div ref={this.end_ref} style={{ marginTop: "100px" }} />
                </div>
                <div className="touchpad" onClick={() => this.showOne()}>
                    {end ? (
                        <span className="blink noselect">◼︎ done</span>
                    ) : (
                        <span className="blink noselect">✦</span>
                    )}
                </div>
            </div>
        );
    }
}